import "./src/styles/global.scss"
import ReactDOM from "react-dom/client"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

export function replaceHydrateFunction() {
  return (element: any, container: any) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "eb8d51f4-e17e-424c-b459-2afa8295f961",
  },
})

appInsights.loadAppInsights()
appInsights.trackPageView()
